.notify {
  &__container {
    display: flex !important;
    height: 72px;
    border-radius: 8px;
    box-shadow: 2px 2px 20px 4px rgba(0, 0, 0, 0.15);
    background-color: $color-primary;
    background-color: transparent;
  }
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    width: 72px;
    height: 72px;
    font-size: 1.75rem;
    &--success {
      background-color: #19a68e;
    }
    &--error {
      background-color: #e44947;
    }
    &--warning {
        background-color: #e4b23e;
    }
  }
  &__content {
    box-sizing: border-box;
    width: 320px;
    background-color: $color-primary;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0 16px;
    @media screen and (max-width: 576px) {
      width: 100%;
    }
  }
  &__title {
    font-size: 1.25rem;
  }
}
