.cardTop {
  width: auto;
  height: 500px;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.85);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 16px 16px;
  border: rgba(0, 0, 0, 0.85) solid;
}

.body {
  &--container {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
      linear-gradient(
        319.6deg,
        rgba(30, 11, 17, 0.75) 0%,
        rgba(19, 55, 113, 0.75) 100%
      );
  }
}

.user {
  &--info {
    width: auto;
    margin: 10px;
    margin-left: 35px;
    opacity: 1;
    background-color: #1e1d1df5;
    border: 0px;
  }

  &--input {
    width: 250px;
  }
}

.line {
  position: absolute;
  width: 8px;
  height: 60px;
  left: 50px;
  top: 60px;

  background: linear-gradient(173.29deg, #133b80 18.88%, #b3191b 172.51%);
}
