.room {
  &__list {
    display: grid;
    grid-template-columns: repeat(3, 192px);
    column-gap: 24px;
    row-gap: 24px;
    justify-content: center;
    margin-top: 32px;
    @media screen and (max-width: 992px) {
      grid-template-columns: repeat(2, 128px);
    }
  }

  &__item {
    color: #fff;
    background-image: linear-gradient(to bottom, #14415a, #000000);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    // height: 256px;
    // border-radius: 16px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.25rem;
    font-weight: 500;
    margin: 0;
    @media screen and (max-width: 992px) {
      height: 192px;
    }
  }

  &__card {
    text-align: center;
    height: 400px;
    width: 700px;
    border-radius: 16px;
    filter: alpha(opacity=90);
    opacity: 0.7;
  }
}
