.navbar {
  height: 64px;
  width: 100%;
  background-color: $color-primary;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.15);
  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 64px;
    @media screen and (max-width: 992px) {
      padding: 0 16px;
    }
  }
  &__menu {
    display: flex;
    position: relative;
    &-item {
      margin-left: 16px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      >i {
        margin-left: 12px;
      }
      &--ads {
        font-weight: 300;
        text-decoration: underline;
      }
    }
  }
  &__dropdown {
    z-index: 99;
    position: absolute;
    top: 44px;
    left: 0;
    width: 240px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    box-shadow: 0 10px 12px 0 rgba(0, 0, 0, 0.1);
    background-color: #3f4476;
    @media screen and (max-width: 992px) {
      left: unset;
      right: 0;
    }
    &-item {
      cursor: pointer;
      padding: 8px 16px;
      > i {
        margin-right: 8px;
      }
    }
    &-divider {
      width: 100%;
      height: 1px;
      background-color: #747aaf;
    }
  }
  &__user {
    @media screen and (max-width: 576px) {
      display: none;
    }
    &--mobile {
      display: none !important;
      @media screen and (max-width: 576px) {
        display: block !important;
      }
    }
  }
}
