.ant-card-body {
  padding: 0 !important;
}

.ant-switch-checked {
  background-color: #00a811 !important;
  border: 0;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: rgb(182, 182, 182) !important;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background: none !important;
}
.ant-menu-item,
.ant-menu-submenu-title {
  padding: 0 0 0 20px;
}

@media screen and (max-width: 540px) {
  .ant-progress-circle{
    width: 250px;
    height: 250px;
    margin-left: -25px !important;
  }
  .ant-progress-text{
    font-size:28px !important;
    margin-left: -10px !important;
  }
  .ant-progress-inner {
    width: 250px;
    height: 250px;
  }
}
