.graph {
  &__container {
    position: relative;
    border-radius: 8px;
    // background-color: #ffffff4b;
  }
  &__watermark {
    z-index: 100;
    opacity: .4;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &__overall {
    @media screen and (max-width: 992px) {
      display: none;
    }
    &--sm {
      display: none;
      @media screen and (max-width: 992px) {
        display: block;
      }
    }
  }
}
