.game {
  &__container {
    padding: 64px 0 0 0;
  }
  &__list {
    display: grid;
    grid-template-columns: repeat(5, 206px);
    row-gap: 16px;
    column-gap: 16px;
    @media screen and (max-width: 576px) {
      grid-template-columns: 1fr;
    }
  }
  &__item {
    position: relative;
    height: 309px;
    width: 206px;
    border-radius: 16px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.4);
    cursor: pointer;
    background-size: cover;
    display: flex;
    align-items: flex-end;
    padding: 16px;
    font-weight: 600;
    font-size: 1.25rem;
    &--mini {
      width: 207px;
      height: 138px;
    }
    &--disabled {
      cursor: not-allowed;
      z-index: 2;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      border-radius: 16px;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6);
    }
  }
  &__banner {
    &-list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      margin-top: 32px;
      row-gap: 16px;
      column-gap: 16px;
      @media screen and (max-width: 576px) {
        grid-template-columns: 1fr;
      }
    }
    &-item {
      height: 210px;
      width: 490px !important;
      border-radius: 16px;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.4);
      font-weight: 600;
      font-size: 1.5rem;
      cursor: pointer;
      background-size: cover;
      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: 576px) {
        font-size: 1.25rem;
        height: 120px;
        width: 240px !important;
      }
    }
    &-text {
      text-align: center;
    }
  }
}
