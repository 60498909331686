.button {
  font-family: "Prompt", sans-serif;
  height: 48px;
  border-radius: 8px;
  border: none;
  font-size: 16px;
  min-width: 96px;
  cursor: pointer;

  >i {
    margin-left: 6px;
  }

  &--primary {
    color: #fff;
    background-image: linear-gradient(to right, #1A3150 , #501A21);
    border: 10px solid;
    border-image-slice: 1;
    border-width: 3px;
    border-image-source: linear-gradient(to right, #006BF6, #B2191B);
    box-shadow: 0 8px 6px -6px black;
  }

  &--success {
    color: #fff;
    background-image: linear-gradient(97deg, #22ff00, #c73636 100%);
  }

  &--ghost {
    color: #fff;
    border: 1px solid #141414;
    background-color: transparent;
  }

  &--block {
    width: 100%;
  }

  &--round {
    border-radius: 24px;
  }

  &:disabled {
    cursor: not-allowed;
    background-image: none;
    background-color: #5a82ff;
  }

  // &__prefix {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  // }

  &__topup {
    background-color: #facc6b;
    background-image: linear-gradient(315deg, #facc6b 0%, #fabc3c 74%);
    color: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: 96px;
    height: 36px;
    border-radius: 18px;
    font-weight: 500;
    @media screen and (max-width: 576px) {
      width: 72px;
      min-width: 72px;
      font-size: .9rem;
    }
  }
  &__link {
    color: #fff;
    background-image: linear-gradient(97deg, #0784f0, #5d45eb 100%);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: 96px;
    height: 36px;
    border-radius: 18px;
    font-weight: 500;
  }

  &__game {
    font-family: "Prompt", sans-serif;
    height: 40px;
    border-radius: 8px;
    border: none;
    font-size: 16px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    cursor: pointer;

    >button {
      height: 40px;
      min-width: 96px;
      color: #fff;
      // border-top-left-radius: 0;
      // border-bottom-left-radius: 0;
      cursor: pointer;
      // @media screen and (max-width: 576px) {
      //   display: none;
      // }
    }

    // >.button__prefix {
    //   border-top-left-radius: 8px;
    //   border-bottom-left-radius: 8px;
    //   height: 40px;
    //   width: 36px;
    //   font-size: 1.15rem;
    //   // @media screen and (max-width: 576px) {
    //   //   border-radius: 8px;
    //   // }
    // }

    &--disabled {
      opacity: 0.6;

      >button,
      .button__prefix {
        cursor: not-allowed;
      }
    }

    &--success {
      >.button__prefix {
        background-color: #3fc380;
      }

      >button {
        background-color: #87d37c;
      }
    }

    &--opposite {
      >.button__prefix {
        background-color: #0093e2;
      }

      >button {
        background-color: #42bbff;
      }
    }

    &--start {
      >.button__prefix {
        background-color: #002453;
      }

      >button {
        background-color: #002453;
      }
    }

    &--pause {
      >.button__prefix {
        background-color: #c77d00;
      }

      >button {
        background-color: #e4b23e;
      }
    }

    &--stop {
      >.button__prefix {
        background-color: #C60606;
      }

      >button {
        background-color: #C60606;
      }
    }
  }
}