.login {
  &__container {
    background-color: $color-primary;
    box-sizing: border-box;
    border-radius: 16px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.15);
    width: 552px;
    padding: 64px;
    text-align: center;
    @media screen and (max-width: 576px) {
      width: 100%;
      padding: 32px 16px;
    }
  }
  &__info {
    &-list {
      display: grid;
      grid-template-columns: 1fr;
      row-gap: 16px;
    }
    &-item {
      display: flex;
      align-items: flex-start;
      > img {
        margin: 0 16px;
      }
    }
    &-title {
      font-size: 1.25rem;
      margin-bottom: 8px;
    }
  }
  &__icon {
    width: 45px;
    margin: 5px 15px 0 0 !important;
  }
}
