.pagination {
  display: inline-block;
  text-align: right;
  padding: 0;
  > li {
    display: inline-block;
    width: 48px;
    height: 48px;
    border: 3px solid #ffb800;
    border-right-width: 0;
    box-sizing: border-box;
    cursor: pointer;
    > a {
      color: rgb(255, 255, 255);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      font-size: 0.9rem;
    }
    &:first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    &:last-child {
      border-right-width: 3px;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    &.active {
      background: #ffb800;
    }
  }
}
