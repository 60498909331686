.setting {
  &__list {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 32px;
  }
  &__item {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 16px;
  }
  &__title {
    font-size: 1.25rem;
    color: #fff;
  }
  &__description {
    font-size: 1rem;
    color: #fff;
  }
  &__option {
    &-list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: 16px;
      column-gap: 16px;
      @media screen and (max-width: 576px) {
        grid-template-columns: 1fr;
      }
    }
    &-list--martingale {
      display: grid;
      grid-template-columns: repeat(7, 48px);
      row-gap: 16px;
      column-gap: 16px;
      @media screen and (max-width: 576px) {
        grid-template-columns: repeat(4, 48px);
      }
      &-value {
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 16px;
        column-gap: 16px;
        @media screen and (max-width: 576px) {
          grid-template-columns: 1fr;
        }
      }
    }
    &-item {
      position: relative;
      text-align: center;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 48px;
      border-radius: 50px;
      background-color: #6F6F6F;
      line-height: 1;
      &--martingale {
        border-radius: 24px;
        &-value {
          display: grid;
          grid-template-columns: 42px 1fr;
          column-gap: 16px;
          align-items: center;
        }
      }
      &--active {
        background: linear-gradient(
          180deg,
          #1a3150 0%,
          #194657 21.35%,
          #178e72 100%
        );
        border: 3px solid #0f1c2d;
        box-sizing: border-box;
        box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.25);
      }
      &--disabled {
        cursor: not-allowed;
        opacity: 0.6;
      }
      > i {
        position: absolute;
        right: 24px;
        align-self: center;
      }
    }
  }
  &__chip {
    &-list {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      row-gap: 16px;
      column-gap: 16px;
      // background : linear-gradient(173.61 deg, rgba(255, 255, 255, 0)5.04%, rgba(255, 255, 255, 0)5.04%, rgba(137, 137, 137, 0.0926976)35.24%, rgba(0, 0, 0, 0.2)70.2%), linear-gradient(90.52 deg, #1A3150 49.48%, #5E1415 100%, #501A21 100%);
      // box-shadow : 0px 4px 4px rgba(0, 0, 0, 0.25);
      // border-radius : 16px;
      justify-self: flex-start;
      @media screen and (max-width: 576px) {
        grid-template-columns: repeat(3, 1fr);
      }
    }
    &-item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 68px;
      height: 68px;
      cursor: pointer;
      background-image: url("/chip-placeholder.svg");
      &--active {
        background-image: url("/chip-active.svg");
      }
    }
  }
}
