.form {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 16px;
  &__item {
    > label {
      display: block;
      margin-bottom: 4px;
    }
  }
  &__control {
    width: 100%;
    height: 48px;
    display: flex;
    background-color: $color-secondary;
    border-radius: 8px;
    padding: 0 16px;
    box-sizing: border-box;
    border: solid 1px #3f4476;
    &--disabled {
      border-color: #747aaf;
      background-color: $color-primary;
    }
    &:focus-within {
      > Input {
        color: #000;
      }
      > i {
        color: #fff;
      }
    }
    > i {
      align-self: center;
      font-size: 16px;
      margin-right: 16px;
      color: $placeholder-color;
    }
    > Input {
      font-family: "Prompt", sans-serif;
      // flex: 1;
      width: 100%;
      background-color: transparent;
      border: none;
      color: $text-color;
      font-size: 16px;
      &:focus {
        outline: none;
      }
      &::placeholder {
        color: $placeholder-color;
      }
    }
  }
  &__group {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 12px;
    row-gap: 12px;
    &-divider {
      position: absolute;
      align-self: center;
      justify-self: center;
      width: 32px;
      height: 32px;
      color: #fff;
      background-image: linear-gradient(to bottom, #1a3150, #194657, #178e72);
      box-shadow: 0 8px 6px -6px black;
      border-radius: 16px;
      font-size: 0.85rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    @media screen and (max-width: 576px) {
      grid-template-columns: 1fr;
    }
  }
}

.formControl {
  width: 100%;
  height: 48px;
}
