.modal {
  width: 520px;
  min-height: 128px;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &--fluid {
    width: 100%;
  }
  &__mask {
    position: fixed;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.85);
    height: 100%;
    width: 100%;
    top: 0 !important;
    left: 0 !important;
    
  }
  // &__close {
  //   color: #fff;
  //   position: fixed;
  //   top: 16px;
  //   right: 16px;
  //   z-index: 1001;
  //   cursor: pointer;
  // }
  &__content {
    font-size: 1.5rem;
    text-align: center;
    &--input {
      text-align: left;
      margin-bottom: 24px;
    }
  }
  &__action {
    display: flex;
    justify-content: flex-end;
    @media screen and (max-width: 576px) {
      flex-direction: column;
      align-items: center;
      margin-top: 16px;
    }
    > .button__game {
      margin-left: 8px;
      @media screen and (max-width: 576px) {
        margin-left: 0px;
        margin-bottom: 8px;
        width: 172px;
      }
    }
    .button__game {
      > .button__prefix {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      > button {
      display: block !important;
      @media screen and (max-width: 576px) {
        width: 100%;
      }
    }
    }
  }
  &__container {
    position: fixed;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}
