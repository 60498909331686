.info {
  //   position: fixed;
  //   z-index: 99;
  //   bottom: 0;
  //   width: 100%;
  //   height: 88px;
  //   background-color: $color-primary;

  //   @media screen and (max-width: 576px) {
  //     height: 112px;
  //   }

  //   > .info__collapse {
  //     position: absolute;
  //     top: -31px;
  //     bottom: unset;
  //   }

  //   &__collapse {
  //     position: fixed;
  //     z-index: 99;
  //     bottom: 0;
  //     width: 100%;
  //     display: flex;
  //     width: 100%;
  //     justify-content: center;
  //     &-button {
  //       padding: 8px;
  //       background-color: $color-primary;
  //       cursor: pointer;
  //       border-top-left-radius: 6px;
  //       border-top-right-radius: 6px;
  //       box-shadow: 0 -10px 12px 0 rgba(0, 0, 0, 0.15);
  //     }
  //   }
  //   &__turnover {
  //     align-self: center;
  //     text-align: right;
  //   }

  //   &__turnover-bar {
  //     display: none;
  //     background-color: #747aaf;
  //     color: #fff;
  //     text-align: center;
  //     height: 24px;
  //     font-size: 14px;
  //     @media screen and (max-width: 576px) {
  //       display: block;
  //     }
  //   }
  //   &__control {
  //     box-sizing: border-box;
  //     width: 100%;
  //     background-color: #202442;
  //     display: none;
  //     row-gap: 8px;
  //     grid-template-columns: 1fr;
  //     position: absolute;
  //     bottom: 112px;
  //     padding: 24px;
  //     left: 0;
  //     box-shadow: 0 -2px 12px 0 rgba(0, 0, 0, 0.15);
  //     @media screen and (max-width: 576px) {
  //       display: grid;
  //     }
  //     & .button__game > button {
  //       width: 100%;
  //     }

  //     &-button {
  //       display: none;

  //       @media screen and (max-width: 576px) {
  //         display: block;
  //       }
  //     }

  //   }

  //   &__container {
  //     display: flex;
  //     align-items: center;
  //     height: 100%;
  //     justify-content: space-between;

  //     @media screen and (max-width: 992px) {
  //       padding-right: 16px;
  //     }
  //   }

  //   &__action {
  //     box-sizing: border-box;
  //     display: grid;
  //     grid-template-columns: repeat(3, 1fr);
  //     column-gap: 8px;
  //     row-gap: 8px;

  //     &--roulette {
  //       grid-template-columns: repeat(4, 1fr);
  //     }

  //     @media screen and (max-width: 576px) {
  //       display: none;
  //     }

  //   }

  //   &__value {
  //     font-size: 1.25rem;
  //     word-break: break-word;
  //   }

  //   &__meta {
  //     font-size: 0.85rem;
  //   }

  &__item {
    position: relative;
    width: 100%;
    text-align: left;
    display: flex;
    flex-direction: column;
    padding: 0 16px;
    justify-content: center;
    // border-radius: 8px;
    // background-color: $color-primary;
    background-color: #ffffff;
    // height: 96px;

    &--half {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__button {
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;
  }

  &__card {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 16px;
  }

  //   &--labouchere {
  //     border-radius: 8px;
  //     background-color: #C4C4C4;
  //     padding: 0px 16px;
  //     height: 48px;
  //     display: flex;
  //     align-items: center;
  //     overflow: auto;
  //   }

  //   &__balance {
  //     &-container {
  //       position: relative;
  //       display: flex;
  //       font-size: 1rem;
  //     }

  //     &-value {
  //       color: #e4b23e;
  //       font-size: 1.5rem;
  //       font-weight: 600;
  //     }
  //   }

  &__progress {
    display: flex;
    width: 100%;
    margin-top: 20px;
    .progress {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      > .progress__current {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  &__switch {
    &-container {
      margin-top: 10px;
      display: grid;
      justify-content: flex-start;
      grid-template-columns: repeat(3, 1fr);
      row-gap: 8px;
      column-gap: 16px;

      @media screen and (max-width: 576px) {
        grid-template-columns: 1fr;
      }
    }

    &-item {
      display: flex;
      color: #ffffff;
      margin: 4px;
      > span {
        margin-left: 8px;
      }
    }
  }

  // .watermark {
  //   position: absolute;
  //   top: 50%;
  //   left: 65%;
  //   transform: translate(-50%, -50%);
  //   opacity: .4;
  // }
}
