@import "./variable.scss";
@import "./navbar.scss";
@import "./layout.scss";
@import "./login.scss";
@import "./button.scss";
@import "./form.scss";
@import "./game.scss";
@import "./room.scss";
@import "./setting.scss";
@import "./info.scss";
@import "./progress.scss";
@import "./graph.scss";
@import "./table.scss";
@import "./user.scss";
@import "./notify.scss";
@import "./pagination.scss";
@import "./modal.scss";
@import "./font-awesome/light.scss";
@import "./font-awesome/solid.scss";
@import "./font-awesome/regular.scss";
@import "./font-awesome/duotone.scss";
@import "./font-awesome/brands.scss";
@import "./font-awesome/fontawesome.scss";
@import url("https://fonts.googleapis.com/css2?family=Prompt:wght@300;400;500;600;700&display=swap");
@import "~react-toastify/dist/ReactToastify.css";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

body {
  color: #fff;
  margin: 0;
  font-family: "Prompt", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3 {
  margin: 0;
  font-weight: 500;
}

a {
  color: #fff;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    color: #fff;
  }
}

/* Chrome, Safari, Edge, Opera */

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  font-size: 16px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  opacity: 1;
  // -webkit-appearance: "Always Show Up/Down Arrows";
}

/* Firefox */
input[type="number"] {
  opacity: 1;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
}
.apexcharts-tooltip {
  border-width: 0 !important;
}

.apexcharts-xaxistooltip {
  display: none !important;
}

.apexcharts-title-text {
  font-family: "Prompt", sans-serif !important;
  font-size: 1.25rem;
  font-weight: 400;
}

.capitalize {
  text-transform: capitalize;
}

.txt-Player,
.txt-TIGER {
  color: #1226db !important;
}

.txt-Banker,
.txt-DRAGON {
  color: #de1245 !important;
}

.graph-tooltip {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 8px;
  color: #65676b;
  background-color: #212121;
  border: 2px solid #00b5ad;
  min-width: 128px;
  padding: 8px 16px;
  font-size: 0.95rem;
  border-radius: 6px;
}

.graph-tooltip-loss {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 8px;
  color: #65676b;
  background-color: #212121;
  border: 2px solid #a3060e;
  min-width: 128px;
  padding: 8px 16px;
  font-size: 0.95rem;
  border-radius: 6px;
}

.graph-tooltip > .title,
.graph-tooltip-loss > .title {
  color: #fff;
}

.profit {
  color: #00b5ad;
}

.loss {
  color: #bb2e59;
}

.Toastify__toast-container {
  width: auto !important;
}

.Toastify__toast {
  padding: 0 !important;
  border-radius: 8px !important;
}

// .slick-slide {
//   margin: 0 8px;
// }

// .slick-list {
//   margin: 0 -8px;
// }

.slick-slider {
  overflow-y: hidden;
  overflow-x: hidden;
}

.__react_component_tooltip .multi-line {
  text-align: left !important;
}
