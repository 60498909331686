.table {
  width: 100%;
  border-radius: 8px;
  background-color: #ffffff67;
  overflow-x: auto;
  border-spacing: 0;
  > thead {
    color: rgb(0, 0, 0);
    background: #636363b2
  }
  & th {
    color: #ffffff;
    font-size: 0.9rem;
    padding: 8px 0;
    font-weight: 500;
    &:first-child {
      border-top-left-radius: 8px;
    }
    &:last-child {
      border-top-right-radius: 8px;
    }
  }
  & td {
    color: #ffffff;
    padding: 8px;
    font-size: 1rem;
  }
  > tbody {
    & tr {
      > td {
        font-size: 0.8rem;
      }
      &:nth-child(even) {
        background-color: #ffffff33;
      }
    }
  }
}
