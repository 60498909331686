.progress {
    position: relative;
    height: 24px;
    width: 100%;
    border-radius: 12px;
    background-color: #ffffff;
    &--reverse {
        transform: rotateY(180deg);
    }
    &__container {
        width: 100%;
    }
    &__turn {
        width: 100%;
        border-radius: 12px;
    }
    &__info {
        text-align: right;
        &--reverse {
            text-align: left;
        }
    }
    &__current {
        position: absolute;
        left: 0;
        top: 0;
        height: 24px;
        border-radius: 12px;
        background-image: linear-gradient(94deg, #8ef5e4, #01c49a);
        box-shadow: 0 0 10px #00ffea;
        max-width: 100%;
        // &--positive {

        // }
        &--negative {
            background-image: linear-gradient(109deg, #f1d187, #e44947);
            box-shadow: 0 0 10px #fc4141;
        }
    }
}
