.layout {
  background-color: $color-secondary;
  min-height: 100vh;
  &--half {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 16px;
    column-gap: 16px;
    align-items: flex-start;
    @media screen and (max-width: 992px) {
      grid-template-columns: 1fr;
    }
    &-sm {
      @media screen and (max-width: 992px) {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
  &--quarter {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    row-gap: 16px;
    column-gap: 16px;
    @media screen and (max-width: 992px) {
      grid-template-columns: 1fr 1fr;
    }
  }
  &--third-one {
    display: grid;
    align-items: baseline;
    grid-template-columns: 3fr 1fr;
    row-gap: 16px;
    column-gap: 16px;
    @media screen and (max-width: 992px) {
      grid-template-columns: 1fr;
    }
  }
  &--main {
    padding: 64px 0 152px 0;
      display: grid;
      grid-template-columns: 1fr;
      row-gap: 16px;
    @media screen and (max-width: 992px) {
      padding: 16px 16px 128px 16px;
    }
    @media screen and (max-width: 576px) {
      padding: 16px 16px 128px 16px;
    }
    &-without-info {
      padding: 64px 0;
      @media screen and (max-width: 992px) {
        padding: 16px
      }
    }
  }
  &--content {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 16px;
  }
}

.container {
  box-sizing: border-box;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
  }
}

.content {
  padding: 0 64px;
  @media screen and (max-width: 992px) {
    padding: 0;
  }
}
